/* AiButton.css */
.ai-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.ai-button button {
  background-color: #ffffff; /* Customize the background color */
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ai-button button:hover {
  background-color: #f0f0f0; /* Customize the hover background color */
}
