:root {
  --app-height: 849px;
  --app-width: 548px;
  --background-color: rgb(16, 22, 31);
  --primary-color: #ffffff;
  --secondary-color: #a0a0a0;
  --button-bg-color: #282c34;
  --button-hover-bg-color: #404450;
  --active-bg-color: #55add2;
  --active-color: #ffffff;
  --border-radius: 5px;
  --font-family: 'Roboto', sans-serif;
  --received-color: #00ff00;
  --sent-color: #ffa200;
  --swap-color: #55add2;
  --success-color: #00ff00;
  --error-color: #ff0000;
}

body {
  font-family: var(--font-family);
  background: var(--background-color);
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: transparent;
  width: 100vh;
  margin: auto;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
}

.input-field {
  background-color: var(--button-bg-color);
  border: 1px solid var(--secondary-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius);
  width: 90%;
  flex-direction: column;
  align-items: center;
}

.input-select {
  background-color: var(--button-bg-color);
  border: 1px solid var(--secondary-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius);
  width: 90%;
  margin-top: 10px;
}

.input-text {
  background-color: var(--button-bg-color);
  border: 1px solid var(--secondary-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius);
  width: 90%;
  margin-top: 10px;
}

.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.floating-label {
  position: absolute;
  top: 12px;
  left: 12px;
  color: var(--secondary-color);
  pointer-events: none;
  transition: 0.2s ease all;
}

.input-text:focus ~ .floating-label,
.input-text:not(:placeholder-shown) ~ .floating-label,
.input-select:focus ~ .floating-label,
.input-select:not([value=""]) ~ .floating-label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: var(--primary-color);
}
.button-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  border-radius: var(--border-radius);
}

.button {
  background-color: var(--button-bg-color);
  color: var(--primary-color);
  border: none;
  padding: 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
  width: 97%;
  margin-top: 10px;
}

.button:hover {
  background-color: var(--button-hover-bg-color);
}

.delete-button {
  background-color: var(--error-color);
}

.delete-button:hover {
  background-color: #cc0000;
}

.error-message {
  color: var(--error-color);
  margin-bottom: 10px;
}

.success-message {
  color: var(--success-color);
  margin-bottom: 10px;
}

/* Navbar styles */
.navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #55add2;
  text-decoration: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-button:hover {
  color: #8bd3f7;
}

.nav-button.active {
  color: var(--active-color);
}

.nav-button span {
  margin-top: 5px;
  font-size: 12px;
}

.logout-button {
  background-color: var(--error-color);
}

.logout-button:hover {
  background-color: #cc0000;
}


.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  padding: 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
}
.tokens-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

/* Bot List Styles */
.bots-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.bot-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--button-bg-color);
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 10px;
  width: 50%;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.bot-info {
  display: flex;
  flex-direction: column;
}

.bot-name {
  font-weight: bold;
}

.exchange {
  color: var(--secondary-color);
}

.delete-button {
  background-color: rgb(119, 119, 119);
  border: none;
  border-radius: var(--border-radius);
  color: var(--primary-color);
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}

/* AiButton.css */




.delete-button:hover {
  background-color: #a8a8a8;
}

.info-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1.5rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  position: relative;
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: 20px;
  border-radius: var(--border-radius);
  min-width: 100vh;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content-body {
  min-width: 100vh;
  font-size: 10px;
}

/* Add these styles to your existing App.css */

.links-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.link-category {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.category-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 10px;
  align-items: center;
  text-align: center;


}

.link-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}

.link-card {
  background-color: var(--button-bg-color);
  background-size: cover;
  background-position: center;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 20px;
  margin: 10px 0;
  width: 100%;
  max-width: 400px; /* Max width of the card */
  text-align: center;
  position: relative;
  color: var(--primary-color);
}

.link-card-overlay {
  border-radius: var(--border-radius);
  padding: 20px;
}

.link-card h3 {
  margin: 10px 0;
  color: var(--primary-color);
}

.link-button {
  background-color: var(--button-bg-color);
  color: var(--primary-color);
  border: none;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 5px;
}


.link-button:hover {
  background-color: var(--button-hover-bg-color);
}
/* AccountPage.css */
/* AccountPage Styles */
.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--app-width);
  height: 70%; /* Assuming the navbar height is 60px */
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--primary-color);
  overflow: hidden;
}

.account-details {
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.user-id, .url-entry {
  display: flex;
  font-size: small;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.copy-button {
  background-color: rgb(128, 128, 128);
  color: var(--primary-color);
  border: none;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.logout-button {
  background-color: rgb(176, 91, 91);
  color: var(--primary-color);
  border: none;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}
.copy-button:hover {
  background-color: var(--button-hover-bg-color);
}

.url-display {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%; /* Adjust width to allow partial visibility of URLs */
}

.copied-message {
  color: var(--success-color);
  font-size: 0.9em;
  margin-left: 10px;
}

.security-alert {
  background-color: black;
  color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius);
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  font-size: 0.9em;
}
.nav-button1 {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #55add2;
  text-decoration: none;

  cursor: pointer;
}
.nav-button1 span {
  margin-top: 3px;
}